import React, { useContext } from "react";
import { AuthContext } from "../components/AuthProvider";

import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { parseContent } from "../helpers/city-parse";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../components/Breadcrumbs";
import SEO from "../components/SEO";
import awardBg from "../images/award_pattern.svg";
import { ClipboardIcon, QuestionMarkCircleIcon, SupportIcon } from "@heroicons/react/solid";

const AwardsPage = ({
  data,
  pageContext,
}) => {
  let { city, service } = pageContext;



  const metaTitle = `Congratulations on Your Award! - ${city} ${service}`;
  const metaDescription = `Searching for ${city} ${service} Near You? Don't Schedule Until You Read This First!`;


  return (
    <Layout bg="gray" header="light" hideHeader={false} transparentBg={true} hideMenu={true}>

      <SEO title={metaTitle} description={metaDescription} robots="noindex" />
      <section className="bg-gray-50 relative overflow-hidden">
        <img src={awardBg} className="absolute bottom-0 z-0 w-full" />
        <div className="max-w-7xl mx-auto  px-4 py-5 sm:px-6 sm:py-4 lg:px-8 text-center z-10 relative">



          <div className="w-full max-w-4xl mx-auto py-3 md:py-6">

            <svg className="mx-auto" width="167" height="208" viewBox="0 0 167 208" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M110.395 134.525C114.277 128.038 119.983 124.715 127.551 124.627C135.118 124.538 138.938 120.683 139.062 113.098C139.168 105.548 142.455 99.8418 148.942 95.9782C155.446 92.097 156.855 86.8777 153.151 80.2672C149.465 73.6744 149.465 67.0816 153.151 60.4711C156.855 53.8783 155.455 48.6679 148.942 44.7867C142.455 40.8966 139.159 35.1988 139.062 27.6135C138.938 20.0637 135.118 16.2091 127.551 16.1205C119.983 16.0141 114.277 12.7089 110.395 6.22244C106.514 -0.281735 101.295 -1.69068 94.7021 2.01333C88.1093 5.69962 81.4988 5.69962 74.906 2.01333C68.3043 -1.68182 63.085 -0.281735 59.2038 6.22244C55.3314 12.7177 49.6159 16.023 42.0572 16.1205C34.5074 16.2091 30.6705 20.0637 30.5641 27.6135C30.4401 35.1988 27.1525 40.8877 20.6661 44.7867C14.1619 48.6679 12.7353 53.8695 16.457 60.4711C20.161 67.0816 20.161 73.6744 16.457 80.2672C12.7353 86.8777 14.1531 92.1059 20.6661 95.9782C27.1525 99.8418 30.4489 105.548 30.5641 113.098C30.6705 120.675 34.5074 124.538 42.0572 124.627C49.6247 124.715 55.3314 128.038 59.2126 134.525C63.0939 141.029 68.3132 142.438 74.906 138.716C81.4988 135.065 88.1093 135.065 94.7021 138.716C101.295 142.429 106.523 141.029 110.395 134.525ZM84.8217 111.778C61.9419 111.778 43.4041 93.2401 43.4041 70.3603C43.4041 47.4982 61.9419 28.9605 84.8217 28.9605C107.684 28.9605 126.222 47.4982 126.222 70.3603C126.222 93.2401 107.684 111.778 84.8217 111.778Z" fill="#2563EB" />
              <path d="M40.0973 130.694C32.1398 130.694 31.0765 126.99 31.0765 126.99L0 188.071L24.9179 185.271L38.6972 206.006C38.6972 206.006 67.4077 146.104 67.4077 146.503C52.5296 146.131 58.4489 131.093 40.0973 130.694Z" fill="#E4E4E4" />
              <path d="M135.535 129.383C112.832 131.758 117.449 137.394 112.256 141.771C106.283 148.143 98.8223 146.893 98.8223 146.893L133.869 207.194L142.491 184.864L167.001 188.063L135.535 129.383Z" fill="#E4E4E4" />
              <path d="M84.1359 27.4733C60.4222 27.4733 41.209 46.9427 41.209 70.9726C41.209 95.0025 60.4222 114.472 84.1359 114.472C107.85 114.472 127.063 95.0025 127.063 70.9726C127.063 46.9427 107.85 27.4733 84.1359 27.4733ZM103.218 57.2458L86.6094 93.7096C84.6707 97.9192 78.5814 96.516 78.5814 92.0277V76.5854H63.3648C58.9354 76.5854 57.5524 70.4147 61.7048 68.4502L97.6891 51.6277C101.004 50.2245 104.602 53.8798 103.218 57.2405V57.2458Z" fill="#2563EB" />
            </svg>

            <h1 style={{ lineHeight: 1.1 }} className="leading-7 max-w-xl text-3xl md:text-5xl font-display font-extrabold text-gray-800 my-3  mx-auto">
              Congratulations on Your Award!
            </h1>
            <p className="text-gray-600 leading-7 text-lg  max-w-3xl mx-auto">
              You’re here because you’re among the best <Link to={`/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}/`} className="tier-underline text-brand-600">{service}
              {service[service.length - 1] == "s" ? " " : "s "}{" "}</Link> in {city}! We’re proud to display your business to our audience of one million people looking for local service providers every month.

            </p>

            <div className="max-w-4xl bg-white shadow-xl mx-auto p-8 md:p-12 rounded-xl my-8 text-left">
              <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-2">Here’s How To Add The Award To Your Website</h3>
              <p className="text-gray-600 leading-7 mb-2"><span className="bg-gray-100 text-gray-900 p-1 rounded-full w-6 h-6 inline-flex items-center justify-center text-sm font-semibold mr-1">1</span> Pick the award badge below that best fits your website style.
                
                </p>
                <p className="text-gray-600 leading-7 mb-2"><span className="bg-gray-100 text-gray-900 p-1 rounded-full w-6 h-6 inline-flex items-center justify-center text-sm font-semibold mr-1">2</span> Copy the code to the right of the award badge, and paste the award code into the code of your website.<br />
                </p>
                <p className="text-gray-600 leading-7 mb-2"><span className="bg-gray-100 text-gray-900 p-1 rounded-full w-6 h-6 inline-flex items-center justify-center text-sm font-semibold mr-1">3</span> That’s it, you’re done!

              </p>

              <div className="mt-8">
              <b>Default</b>
              <div className="border border-gray-100 grid grid-cols-3 rounded-xl mt-2">
                <div className="p-6 col-span-2 md:col-span-1">
                  <b>Preview</b>
                  <img
                    src={`https://api.localtier.com/images/award/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}`}
                    className="py-2"
                  />

                </div>
                <div className="px-6 md:px-2 py-6 col-span-3 md:col-span-2 w-full h-full">
                  <b>Code</b>
                  <div className="bg-gray-100 rounded-xl p-4 mt-2 text-sm overflow-scroll"><code>{`<a href="https://www.localtier.com/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}/" style="display:inline-block; border:0;"><img style="width:218px; display:block;" width="218" height="272" src="https://api.localtier.com/images/award/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}" alt="Best ${city} ${service}" /></a>`}</code></div>
                  <button onClick={() => { navigator.clipboard.writeText(`<a href="https://www.localtier.com/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}/" style="display:inline-block; border:0;"><img style="width:218px; display:block;" width="218" height="272" src="https://api.localtier.com/images/award/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}" alt="Best ${city} ${service}" /></a>`) }}
                    className="transition hover:border-gray-200 hover:text-gray-900 border border-gray-100 px-4 py-2 mt-2 rounded-xl text-sm text-gray-600 flex items-center"><ClipboardIcon className="text-gray-300 w-5 h-5 mr-1" /> Copy Code to Clipboard</button>
                </div>
              </div>   
              </div>


              
              <div className="mt-6">
              <b>Light</b>
              <div className="border border-gray-100 grid grid-cols-3 rounded-xl mt-2">
               
                <div className="p-6 col-span-2 md:col-span-1">
                  <b>Preview</b>
                  <img
                    src={`https://api.localtier.com/images/award/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}?theme=light`}
                    sclassName="py-2"
                  />

                </div>
                <div className="px-6 md:px-2 py-6 col-span-3 md:col-span-2 w-full h-full">
                  <b>Code</b>
                  <div className="bg-gray-100 rounded-xl p-4 mt-2 text-sm overflow-scroll"><code>{`<a href="https://www.localtier.com/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}/" style="display:inline-block; border:0;"><img style="width:218px; display:block;" width="218" height="272" src="https://api.localtier.com/images/award/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}?theme=light" alt="Best ${city} ${service}" /></a>`}</code></div>
                  <button onClick={() => { navigator.clipboard.writeText(`<a href="https://www.localtier.com/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}/" style="display:inline-block; border:0;"><img style="width:218px; display:block;" width="218" height="272" src="https://api.localtier.com/images/award/${data.strapiServiceAreaRank.city.Slug}/${data.strapiServiceAreaRank.service.Slug}?theme=light" alt="Best ${city} ${service}" /></a>`) }}
                    className="transition hover:border-gray-200 hover:text-gray-900 border border-gray-100 px-4 py-2 mt-2 rounded-xl text-sm text-gray-600 flex items-center"><ClipboardIcon className="text-gray-300 w-5 h-5 mr-1" /> Copy Code to Clipboard</button>
                </div>
              </div>
              </div>
              <div className=" rounded-xl p-3 px-6 bg-gray-50 text-sm text-gray-600 mt-8 flex items-top">

         <QuestionMarkCircleIcon className="text-gray-300 w-12 h-12 mr-2"/> <p className="mt-1">Need help? If you need any help, respond to the email you received, or <a href="mailto:hello@localtier.com" className="text-brand-600">click here to send an email</a> to the LocalTier Success Team. We can even update your website for you!</p>

              </div>


            </div>

          </div>
          
        </div>
      </section>



    </Layout>
  );
};

export default AwardsPage;

export const ServiceAreaPageQuery = graphql`
  query ServiceAreaPageAwardsQuery($id: String!) {
    localTierPlaceHolderImage: file(relativePath: { eq: "lt_main.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    strapiServiceAreaRank(id: { eq: $id }) {
      strapiId
      RankedList {
        business {
          id
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, placeholder: BLURRED)
              }
            }
          }
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, placeholder: BLURRED)
              }
            }
          }
          Name
          Description
          URL
          phone
          Slug
          Address
          City
          ZIP
          State
        }
      }
      seoImage {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: BLURRED)
          }
        }
      }
      city {
        id
        Slug
      }
      service {
        id
        Name
        Slug
        excerpt
        seooptimized
        introcontent
        footercontent
        heroimage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
